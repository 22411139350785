<template>
    <modal :nestModal="nestedModal" :loading="updateLoading" :open="openAirMsgModal" @close="$emit('closeModal')" @save="updateMessage" :modalData="modalData">
      <template v-slot:modalContent>
        <v-tabs vertical @change="tabItemIndex = $event">
            <v-tab class="font-weight-bold justify-start">
                On Air Message
            </v-tab>
            <v-tab class="font-weight-bold justify-start">
                long term message
            </v-tab>
            <v-tab-item>
            <div v-if="tabItemIndex == 0">
                <EditorTextbox placeholder="Write on air message..." @inModalTrigger="nestedModal=$event" @updateContent="model.ON_AIR_SHORT_MESSAGE = $event;" :setContent="model.ON_AIR_SHORT_MESSAGE" height="500"/>
            </div>
            </v-tab-item>
            <v-tab-item>
            <div v-if="tabItemIndex == 1">
                <EditorTextbox placeholder="Write long term message..." @inModalTrigger="nestedModal=$event" @updateContent="model.ON_AIR_LONG_MESSAGE = $event;" :setContent="model.ON_AIR_LONG_MESSAGE" height="500"/>
            </div>
            </v-tab-item>
         </v-tabs>
      </template>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { EditorTextbox } from "@/components/common";

export default {
    props: ['openAirMsgModal'],
    emits: ['closeModal'],
    components: {
        EditorTextbox,
        Modal: () => import("@/components/common/modal"),
    },
    data () {
        return {
            modalData: {
                title: 'update on air message',
                saveBtnTxt: 'Save',
                width: 1000,
                maxHeight: '700px'
            },
            model: {
                ON_AIR_SHORT_MESSAGE: null,
                ON_AIR_LONG_MESSAGE: null,
            },
            updateLoading: false,
            tabItemIndex: 0,
            nestedModal: false
        }
    },
    computed: {
        ...mapGetters(["onAirMsgModel"]),

        onAirText() {
            if (this.model.ON_AIR_SHORT_MESSAGE) return JSON.stringify(this.model.ON_AIR_SHORT_MESSAGE);
            return '';
        },
        longTermText() {
            if (this.model.ON_AIR_LONG_MESSAGE) return JSON.stringify(this.model.ON_AIR_LONG_MESSAGE);
            return '';
        },
        onAirModelTxt() {
            if (this.onAirMsgModel.ON_AIR_SHORT_MESSAGE) return JSON.stringify(this.onAirMsgModel.ON_AIR_SHORT_MESSAGE);
            return '';
        },
        longTermModelTxt() {
            if (this.onAirMsgModel.ON_AIR_LONG_MESSAGE) return JSON.stringify(this.onAirMsgModel.ON_AIR_LONG_MESSAGE);
            return '';
        }
    },
    created() {
        this.model = this.$helpers.cloneData(this.onAirMsgModel);
    },
    methods: {
        ...mapActions(["updateBulkOptData"]),

        updateMessage() {
            if ((this.model.ON_AIR_SHORT_MESSAGE && this.onAirText != this.onAirModelTxt) || (this.model.ON_AIR_LONG_MESSAGE && this.longTermText != this.longTermModelTxt)) {
                this.updateLoading = true;
                const reqData = [];
                if(this.model.ON_AIR_SHORT_MESSAGE && this.onAirText != this.onAirModelTxt) reqData.push({ option_value: this.model.ON_AIR_SHORT_MESSAGE, option_name: 'ON_AIR_SHORT_MESSAGE' })
                if(this.model.ON_AIR_LONG_MESSAGE && this.longTermText != this.longTermModelTxt) reqData.push({ option_value: this.model.ON_AIR_LONG_MESSAGE, option_name: 'ON_AIR_LONG_MESSAGE' })
                this.updateBulkOptData(reqData).then((resp) => {
                    this.$store.commit("setOnAirMsgModel", this.model);
                    this.$emit('closeModal');
                    this.updateLoading = false;
                }).catch((err) => this.updateLoading = false);
            }
            this.$emit('closeModal');
        }
    }

}
</script>
